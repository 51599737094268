// Vendor
import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Helpers
import mergeArrayByKey from 'src/helpers/mergeArrayByKey';

// Redux
import { RootState } from 'src/store';
import {
  getAll,
  getAllIdentityVerificationEnabled,
  getDocumentListProperties,
  getIdentityVerificationPropertyEnabled,
  getPropertiesDDownThunk,
  propertyCreateAgentsThunk,
  propertyCreateThunk,
  propertyDeleteInvitationRelationThunk,
  propertyDeleteUserRelationThunk,
  propertyGetAgentsThunk,
  propertyGetByIdThunk,
  propertyUpdateStatusThunk,
  propertyUpdateThunk
} from './services';

// Enums
import { Status as StatusTypes } from 'src/ts/enums';

// Types
import {
  IPaginationRequest,
  IProperty,
  IPropertyAgents,
  IRejectedAction,
  ISerializedError
} from 'src/ts/interfaces';

// Constants
import { IDENTITY_VERIFICATION_FILTER, NAME } from './constants';

interface StateData {
  getOne: {
    data?: IProperty | null;
    status: StatusTypes;
    error?: ISerializedError | AxiosError | null;
    agents: {
      getAll: {
        data: IPropertyAgents;
        status: StatusTypes;
        error?: ISerializedError | AxiosError | string | null;
      };
      remove: {
        status: StatusTypes;
        error?: ISerializedError | AxiosError | null;
      };
      create: {
        status: StatusTypes;
        error?: ISerializedError | AxiosError | null;
      };
    };
  };
  getAll: {
    status: StatusTypes;
    data?: IProperty[] | null;
    error?: ISerializedError | AxiosError | null;
    count?: number;
    filter: IPaginationRequest;
  };
  documentLinkListProperties: {
    status: StatusTypes;
    data?: IProperty[] | null;
    error?: ISerializedError | AxiosError | null;
    count?: number;
    filter: IPaginationRequest;
  };
  getAllIdentityVerificationEnabled: {
    status: StatusTypes;
    data?: IProperty[] | null;
    error?: ISerializedError | AxiosError | null;
    count: number;
    filter: IPaginationRequest;
  };
  hasIdentityVerificationPropertyEnabled: {
    status: StatusTypes;
    data?: boolean | null;
    error?: ISerializedError | AxiosError | null;
  };
  remove: {
    status: StatusTypes;
    error?: ISerializedError | AxiosError | null;
  };
  create: {
    status: StatusTypes;
    error?: ISerializedError | AxiosError | null;
    data: IProperty | null;
  };
  update: {
    status: StatusTypes;
    error?: ISerializedError | AxiosError | null;
  };
  updateStatus: {
    status: StatusTypes;
    error?: ISerializedError | AxiosError | null;
  };
  getPropertiesDDown: {
    status: StatusTypes;
    data?: IProperty[] | null;
    error?: string | null;
    count?: number;
    filter: IPaginationRequest;
  };
}

const initialState: StateData = {
  getOne: {
    data: null,
    status: StatusTypes.IDLE,
    error: null,
    agents: {
      getAll: {
        data: {
          users: [],
          invitations: []
        },
        status: StatusTypes.IDLE,
        error: null
      },
      remove: {
        status: StatusTypes.IDLE,
        error: null
      },
      create: {
        status: StatusTypes.IDLE,
        error: null
      }
    }
  },
  getAll: {
    status: StatusTypes.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc',
      filter: 'status!=ARCHIVED'
    }
  },
  documentLinkListProperties: {
    status: StatusTypes.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc',
      filter: 'status!=ARCHIVED'
    }
  },
  getAllIdentityVerificationEnabled: {
    status: StatusTypes.IDLE,
    data: [],
    error: null,
    count: -1,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc',
      filter: IDENTITY_VERIFICATION_FILTER
    }
  },
  hasIdentityVerificationPropertyEnabled: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  },
  remove: {
    status: StatusTypes.IDLE,
    error: null
  },
  create: {
    status: StatusTypes.IDLE,
    error: null,
    data: null
  },
  update: {
    status: StatusTypes.IDLE,
    error: null
  },
  updateStatus: {
    status: StatusTypes.IDLE,
    error: null
  },
  getPropertiesDDown: {
    status: StatusTypes.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc',
      filter: 'status!=ARCHIVED'
    }
  }
};

const getAllReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAll.pending, (state: StateData) => {
    state.getAll.status = StatusTypes.LOADING;
    state.getAll.error = null;
  });
  builder.addCase(getAll.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAll.status = StatusTypes.ERROR;
    state.getAll.error = (action.payload as AxiosError) || action.error;
  });
  builder.addCase(getAll.fulfilled, (state: StateData, action: any) => {
    const { data, count, page, rowsPerPage } = action.payload;
    state.getAll.status = StatusTypes.SUCCESS;
    state.getAll.data = data;
    state.getAll.count = count;
    state.getAll.filter.page = page > 0 ? page - 1 : page;
    state.getAll.filter.rowsPerPage = rowsPerPage;
    state.getAll.filter.q = action?.meta?.arg.q || '';
  });
};

const documentLinkListPropertiesReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getDocumentListProperties.pending, (state: StateData) => {
    state.documentLinkListProperties.status = StatusTypes.LOADING;
    state.documentLinkListProperties.error = null;
  });
  builder.addCase(
    getDocumentListProperties.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.documentLinkListProperties.status = StatusTypes.ERROR;
      state.documentLinkListProperties.error = (action.payload as AxiosError) || action.error;
    }
  );
  builder.addCase(getDocumentListProperties.fulfilled, (state: StateData, action: any) => {
    const { data, count, page, rowsPerPage } = action.payload;
    state.documentLinkListProperties.status = StatusTypes.SUCCESS;
    state.documentLinkListProperties.data = data;
    state.documentLinkListProperties.count = count;
    state.documentLinkListProperties.filter.page = page > 0 ? page - 1 : page;
    state.documentLinkListProperties.filter.rowsPerPage = rowsPerPage;
    state.documentLinkListProperties.filter.q = action?.meta?.arg.q || '';
    state.documentLinkListProperties.filter.filter = action?.meta?.arg.filter;
  });
};

const getAllIdentityVerificationEnabledReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAllIdentityVerificationEnabled.pending, (state: StateData) => {
    state.getAllIdentityVerificationEnabled.status = StatusTypes.LOADING;
    state.getAllIdentityVerificationEnabled.error = null;
  });
  builder.addCase(
    getAllIdentityVerificationEnabled.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.getAllIdentityVerificationEnabled.status = StatusTypes.ERROR;
      state.getAllIdentityVerificationEnabled.error =
        (action.payload as AxiosError) || action.error;
    }
  );
  builder.addCase(getAllIdentityVerificationEnabled.fulfilled, (state: StateData, action: any) => {
    const { data, count, page, rowsPerPage } = action.payload;
    state.getAllIdentityVerificationEnabled.status = StatusTypes.SUCCESS;
    state.getAllIdentityVerificationEnabled.data = data;
    state.getAllIdentityVerificationEnabled.count = count;
    state.getAllIdentityVerificationEnabled.filter.page = page > 0 ? page - 1 : page;
    state.getAllIdentityVerificationEnabled.filter.rowsPerPage = rowsPerPage;
    state.getAllIdentityVerificationEnabled.filter.q = action?.meta?.arg.q || '';
  });
};
const hasIdentityVerificationPropertyEnabledReducer = (
  builder: ActionReducerMapBuilder<StateData>
) => {
  builder.addCase(getIdentityVerificationPropertyEnabled.pending, (state: StateData) => {
    state.hasIdentityVerificationPropertyEnabled.status = StatusTypes.LOADING;
    state.hasIdentityVerificationPropertyEnabled.error = null;
  });
  builder.addCase(
    getIdentityVerificationPropertyEnabled.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.hasIdentityVerificationPropertyEnabled.status = StatusTypes.ERROR;
      state.hasIdentityVerificationPropertyEnabled.error =
        (action.payload as AxiosError) || action.error;
    }
  );
  builder.addCase(
    getIdentityVerificationPropertyEnabled.fulfilled,
    (state: StateData, action: any) => {
      const { idv_enabled } = action.payload;
      state.hasIdentityVerificationPropertyEnabled.status = StatusTypes.SUCCESS;
      state.hasIdentityVerificationPropertyEnabled.data = idv_enabled;
    }
  );
};
const getByIdReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(propertyGetByIdThunk.pending, (state: StateData) => {
    state.getOne.status = StatusTypes.LOADING;
    state.getOne.error = null;
  });
  builder.addCase(propertyGetByIdThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.getOne.status = StatusTypes.ERROR;
    state.getOne.error = (action.payload as AxiosError) || action.error;
  });
  builder.addCase(propertyGetByIdThunk.fulfilled, (state, action: PayloadAction<any>) => {
    state.getOne.status = StatusTypes.SUCCESS;
    state.getOne.data = action.payload.data;
  });
};

const createReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(propertyCreateThunk.pending, (state: StateData) => {
    state.create.status = StatusTypes.LOADING;
    state.create.error = null;
  });
  builder.addCase(propertyCreateThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.create.status = StatusTypes.ERROR;
    state.create.error = (action.payload as AxiosError) || action.error;
  });
  builder.addCase(propertyCreateThunk.fulfilled, (state, action) => {
    state.create.status = StatusTypes.SUCCESS;
    state.create.data = action.payload.data as IProperty;
  });
};

const updateReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(propertyUpdateThunk.pending, (state: StateData) => {
    state.update.status = StatusTypes.LOADING;
    state.update.error = null;
  });
  builder.addCase(propertyUpdateThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.update.status = StatusTypes.ERROR;
    state.update.error = (action.payload as AxiosError) || action.error;
  });
  builder.addCase(propertyUpdateThunk.fulfilled, (state, action) => {
    state.update.status = StatusTypes.SUCCESS;
    state.getOne.data = action.payload.data;
  });
};

const updateStatusReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(propertyUpdateStatusThunk.pending, (state: StateData) => {
    state.updateStatus.status = StatusTypes.LOADING;
    state.updateStatus.error = null;
  });
  builder.addCase(
    propertyUpdateStatusThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.updateStatus.status = StatusTypes.ERROR;
      state.updateStatus.error = (action.payload as AxiosError) || action.error;
    }
  );
  builder.addCase(propertyUpdateStatusThunk.fulfilled, (state, action: any) => {
    state.updateStatus.status = StatusTypes.SUCCESS;

    const propertyId = action?.meta?.arg?.id;
    const status = action?.meta?.arg?.request?.property?.status;
    state.getAll.data?.map((property: IProperty) => {
      if (property?.id === propertyId) {
        property.status = status;
      }
      return property;
    });
  });
};

const getAgentsReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(propertyGetAgentsThunk.pending, (state: StateData) => {
    state.getOne.agents.getAll.status = StatusTypes.LOADING;
    state.getOne.agents.getAll.error = null;
  });
  builder.addCase(propertyGetAgentsThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.getOne.agents.getAll.status = StatusTypes.ERROR;
    state.getOne.agents.getAll.error = (action.payload as AxiosError) || action.error;
  });
  builder.addCase(propertyGetAgentsThunk.fulfilled, (state, action) => {
    state.getOne.agents.getAll.status = StatusTypes.SUCCESS;
    state.getOne.agents.getAll.data = action.payload.data as IPropertyAgents;
  });
};

const createAgentsReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(propertyCreateAgentsThunk.pending, (state: StateData) => {
    state.getOne.agents.create.status = StatusTypes.LOADING;
    state.getOne.agents.create.error = null;
  });
  builder.addCase(
    propertyCreateAgentsThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.getOne.agents.create.status = StatusTypes.ERROR;
      state.getOne.agents.create.error = (action.payload as AxiosError) || action.error;
    }
  );
  builder.addCase(propertyCreateAgentsThunk.fulfilled, (state, action) => {
    state.getOne.agents.create.status = StatusTypes.SUCCESS;
  });
};

const deleteUserRelationReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(propertyDeleteUserRelationThunk.pending, (state: StateData) => {
    state.getOne.agents.remove.status = StatusTypes.LOADING;
    state.getOne.agents.remove.error = null;
  });
  builder.addCase(
    propertyDeleteUserRelationThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.getOne.agents.remove.status = StatusTypes.ERROR;
      state.getOne.agents.remove.error = (action.payload as AxiosError) || action.error;
    }
  );
  builder.addCase(propertyDeleteUserRelationThunk.fulfilled, (state, action) => {
    state.getOne.agents.remove.status = StatusTypes.SUCCESS;

    const users = state.getOne.agents?.getAll?.data?.users?.filter(
      (user) => user.id !== action.payload
    );
    state.getOne.agents.getAll.data.users = users;
  });
};

const deleteInvitationRelationReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(propertyDeleteInvitationRelationThunk.pending, (state: StateData) => {
    state.getOne.agents.remove.status = StatusTypes.LOADING;
    state.getOne.agents.remove.error = null;
  });
  builder.addCase(
    propertyDeleteInvitationRelationThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.getOne.agents.remove.status = StatusTypes.ERROR;
      state.getOne.agents.remove.error = (action.payload as AxiosError) || action.error;
    }
  );
  builder.addCase(propertyDeleteInvitationRelationThunk.fulfilled, (state, action) => {
    state.getOne.agents.remove.status = StatusTypes.SUCCESS;

    const invitations = state.getOne.agents?.getAll?.data?.invitations?.filter(
      (invitation) => invitation.id !== action.payload
    );
    state.getOne.agents.getAll.data.invitations = invitations;
  });
};

export const getPropertiesDDownReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getPropertiesDDownThunk.pending, (state: StateData) => {
    state.getPropertiesDDown.status = StatusTypes.LOADING;
    state.getPropertiesDDown.error = null;
  });
  builder.addCase(getPropertiesDDownThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.getPropertiesDDown.status = StatusTypes.ERROR;
    state.getPropertiesDDown.error = action.error?.message || null;
  });
  builder.addCase(
    getPropertiesDDownThunk.fulfilled,
    (state: StateData, action: PayloadAction<any>) => {
      const { data, count, page, rowsPerPage } = action.payload;
      state.getPropertiesDDown.status = StatusTypes.SUCCESS;
      state.getPropertiesDDown.data =
        data.length > 0 ? mergeArrayByKey(state.getPropertiesDDown.data || [], data, 'id') : [];
      state.getPropertiesDDown.count = count;
      state.getPropertiesDDown.filter.page = page
        ? page > 0
          ? page - 1
          : page
        : initialState.getPropertiesDDown.filter.page;
      state.getPropertiesDDown.filter.rowsPerPage =
        rowsPerPage || initialState.getPropertiesDDown.filter.rowsPerPage;
    }
  );
};

const propertySlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetGetAll: (state: StateData) => {
      state.getAll = initialState.getAll;
    },
    resetGetPropertiesDDown: (state: StateData) => {
      state.getPropertiesDDown = initialState.getPropertiesDDown;
    },
    resetUpdateStatus: (state: StateData) => {
      state.updateStatus = initialState.updateStatus;
    },
    resetGetById: (state: StateData) => {
      state.getOne = initialState.getOne;
    },
    resetCurrentPropertyState: (state: StateData) => {
      state.getOne = initialState.getOne;
    },
    resetRemove: (state: StateData) => {
      state.getOne.agents.remove = initialState.getOne.agents.remove;
    },
    resetCreate: (state: StateData) => {
      state.create = initialState.create;
    },
    resetUpdate: (state: StateData) => {
      state.update = initialState.update;
    },
    resetCreateAgent: (state: StateData) => {
      state.getOne.agents.create = initialState.getOne.agents.create;
    }
  },
  extraReducers: (builder) => {
    getAllReducer(builder);
    documentLinkListPropertiesReducer(builder);
    getAllIdentityVerificationEnabledReducer(builder);
    hasIdentityVerificationPropertyEnabledReducer(builder);
    getByIdReducer(builder);
    createReducer(builder);
    updateReducer(builder);
    updateStatusReducer(builder);
    getAgentsReducer(builder);
    deleteUserRelationReducer(builder);
    deleteInvitationRelationReducer(builder);
    createAgentsReducer(builder);
    getPropertiesDDownReducer(builder);
  }
});

// Utility to create status-related selectors
const createListSelectors = (basePath: (state: RootState) => any) => ({
  isLoading: (state: RootState) => state[NAME].getAll.status === StatusTypes.LOADING,
  isIdle: (state: RootState) => basePath(state).status === StatusTypes.IDLE,
  isSuccess: (state: RootState) => basePath(state).status === StatusTypes.SUCCESS,
  error: (state: RootState) => basePath(state).error,
  data: (state: RootState) => basePath(state).data || [],
  filter: (state: RootState) => basePath(state).filter || 'status!=ARCHIVED',
  count: (state: RootState) => basePath(state).count || 0
});

export const selectors = {
  getOne: {
    data: (state: RootState) => state[NAME].getOne.data,
    error: (state: RootState) => state[NAME].getOne.error,
    isSuccessful: (state: RootState) => state[NAME].getOne.status === StatusTypes.SUCCESS,
    isLoading: (state: RootState) => state[NAME].getOne.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getOne.status === StatusTypes.IDLE,
    agents: {
      getAll: {
        data: (state: RootState) => state[NAME].getOne.agents.getAll.data,
        isLoading: (state: RootState) =>
          state[NAME].getOne.agents.getAll.status === StatusTypes.LOADING,
        error: (state: RootState) => state[NAME].getOne.agents.getAll.error
      },
      create: {
        isLoading: (state: RootState) =>
          state[NAME].getOne.agents.create.status === StatusTypes.LOADING,
        isCreated: (state: RootState) =>
          state[NAME].getOne.agents.create.status === StatusTypes.SUCCESS,
        error: (state: RootState) => state[NAME].getOne.agents.create.error
      },
      remove: {
        isLoading: (state: RootState) =>
          state[NAME].getOne.agents.remove.status === StatusTypes.LOADING,
        isRemoved: (state: RootState) =>
          state[NAME].getOne.agents.remove.status === StatusTypes.SUCCESS,
        error: (state: RootState) => state[NAME].getOne.agents.remove.error
      }
    }
  },
  getAll: {
    ...createListSelectors((state: RootState) => state[NAME].getAll),
    isToolbarDisabled: (state: RootState) => {
      const stateGetAll = state[NAME].getAll;
      return (
        stateGetAll.status === StatusTypes.LOADING ||
        stateGetAll.error !== null ||
        (stateGetAll.count === 0 && stateGetAll.filter.q === '')
      );
    }
  },
  documentLinkListProperties: {
    ...createListSelectors((state: RootState) => state[NAME].documentLinkListProperties)
  },
  getAllIdentityVerificationEnabled: {
    isLoading: (state: RootState) =>
      state[NAME].getAllIdentityVerificationEnabled.status === StatusTypes.LOADING,
    isIdle: (state: RootState) =>
      state[NAME].getAllIdentityVerificationEnabled.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].getAllIdentityVerificationEnabled.error,
    data: (state: RootState) =>
      state[NAME].getAllIdentityVerificationEnabled.data ||
      initialState.getAllIdentityVerificationEnabled.data,
    filter: (state: RootState) =>
      state[NAME].getAllIdentityVerificationEnabled.filter ||
      initialState.getAllIdentityVerificationEnabled.filter,
    count: (state: RootState) => state[NAME].getAllIdentityVerificationEnabled.count,
    isToolbarDisabled: (state: RootState) => {
      const stateGetAllIdentityVerificationEnabled = state[NAME].getAllIdentityVerificationEnabled;
      return (
        stateGetAllIdentityVerificationEnabled.status === StatusTypes.LOADING ||
        stateGetAllIdentityVerificationEnabled.error !== null ||
        (stateGetAllIdentityVerificationEnabled.count === 0 &&
          stateGetAllIdentityVerificationEnabled.filter.q === '')
      );
    }
  },
  hasIdentityVerificationPropertyEnabled: {
    isLoading: (state: RootState) =>
      state[NAME].hasIdentityVerificationPropertyEnabled.status === StatusTypes.LOADING,
    isIdle: (state: RootState) =>
      state[NAME].hasIdentityVerificationPropertyEnabled.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].hasIdentityVerificationPropertyEnabled.error,
    data: (state: RootState) => state[NAME].hasIdentityVerificationPropertyEnabled.data
  },
  update: {
    isLoading: (state: RootState) => state[NAME].update.status === StatusTypes.LOADING,
    isUpdated: (state: RootState) => state[NAME].update.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].update.error
  },
  updateStatus: {
    isLoading: (state: RootState) => state[NAME].updateStatus.status === StatusTypes.LOADING,
    isUpdated: (state: RootState) => state[NAME].updateStatus.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].updateStatus.error
  },
  create: {
    isLoading: (state: RootState) => state[NAME].create.status === StatusTypes.LOADING,
    isSuccessful: (state: RootState) => state[NAME].create.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].create.error,
    data: (state: RootState) => state[NAME].create.data
  },
  getPropertiesDDown: (state: RootState) => state[NAME].getPropertiesDDown
};

export const { reducer, actions } = propertySlice;
