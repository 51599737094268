import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, isAxiosError } from 'src/api/instance';
import {
  IProperty,
  IPropertyAgentsRequest,
  IPropertyRequest,
  IPropertyResponse,
  IPropertyStatusChangeRequest,
  deleteInvitationRequest,
  deleteUserRequest,
  getPaginationRequest,
  idRequest,
  updateByIdRequest
} from 'src/ts/interfaces';
import api from './api';
import { NAME } from './constants';
import { updatePropertyLogo } from './utils/updatePropertyLogo';
import { uploadPropertyLogo } from './utils/uploadPropertyLogo';

export const getAll = createAsyncThunk(`${NAME}/getAll`, async (data: getPaginationRequest) => {
  try {
    return await api.getAll(data);
  } catch (error) {
    console.error(`${NAME}/getAll`, error);
    throw new Error('Error fetching the properties');
  }
});

export const getDocumentListProperties = createAsyncThunk(
  `${NAME}/getDocumentListProperties`,
  async (data: getPaginationRequest) => {
    try {
      return await api.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getDocumentListProperties`, error);
      throw new Error('Error fetching document link list the properties');
    }
  }
);

export const getAllIdentityVerificationEnabled = createAsyncThunk(
  `${NAME}/getAllIdentityVerificationEnabled`,
  async (data: getPaginationRequest) => {
    try {
      return await api.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getAllIdentityVerificationEnabled`, error);
      throw new Error('Error fetching the identity verification enabled properties');
    }
  }
);
export const getIdentityVerificationPropertyEnabled = createAsyncThunk(
  `${NAME}/getIdentityVerificationPropertyEnabled`,
  async () => {
    try {
      return await api.getIdentityVerificationPropertyEnabled();
    } catch (error) {
      console.error(`${NAME}/getIdentityVerificationPropertyEnabled`, error);
      throw new Error('Error fetching if identity verification property is enabled');
    }
  }
);
export const getPropertiesDDownThunk = createAsyncThunk(
  `${NAME}/getPropertiesDDownThunk`,
  async (data: getPaginationRequest) => {
    try {
      return await api.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getPropertiesDDownThunk`, error);
      throw new Error(`Error fetching the properties for DropDown`);
    }
  }
);

export const propertyGetAllThunk = createAsyncThunk(
  `${NAME}/getAllThunk`,
  async (payload: getPaginationRequest, thunkApi) => {
    try {
      // The await here is important because without it the catch will not be triggered since the call it's not being awaited
      return await api.getAll(payload);
    } catch (error) {
      if (isAxiosError(error)) {
        // Add axios error to the state. All the properties of AxiosError type can be accessed on action.payload
        return thunkApi.rejectWithValue(error as AxiosError);
      }
      throw error;
    }
  }
);

export const propertyGetByIdThunk = createAsyncThunk(`${NAME}/getById`, async (payload: string) => {
  try {
    return await api.getById(payload);
  } catch (error) {
    console.error(`${NAME}/getById`, error);
    throw new Error(`Error fetching the property by id: ${payload}`);
  }
});

export const propertyCreateThunk = createAsyncThunk(
  `${NAME}/create`,
  async (payload: IPropertyRequest) => {
    try {
      const propertyResponse = await api.create(payload);
      await uploadPropertyLogo(propertyResponse?.data as IProperty, payload);
      return propertyResponse;
    } catch (error) {
      console.error(`${NAME}/create`, error);
      throw new Error('Error creating property');
    }
  }
);

export const propertyUpdateThunk = createAsyncThunk(
  `${NAME}/update`,
  async (payload: updateByIdRequest<IPropertyRequest>) => {
    try {
      const updatedProperty = await api.update(payload);
      const updatedLogo = (await updatePropertyLogo(
        updatedProperty?.data as IProperty,
        payload
      )) as unknown as IPropertyResponse;

      return { ...updatedProperty, logo: updatedLogo?.data?.logo };
    } catch (error) {
      console.error(`${NAME}/update`, error);
      throw new Error('Error updating property');
    }
  }
);

export const propertyUpdateStatusThunk = createAsyncThunk(
  `${NAME}/updatePropertyStatus`,
  async (payload: updateByIdRequest<IPropertyStatusChangeRequest>) => {
    try {
      return await api.updateStatus(payload);
    } catch (error) {
      console.error(`${NAME}/updatePropertyStatus`, error);
      throw new Error('Error updating property status');
    }
  }
);

export const propertyDeleteThunk = createAsyncThunk(
  `${NAME}/delete`,
  async (payload: idRequest) => {
    try {
      return await api.delete(payload);
    } catch (error) {
      console.error(`${NAME}/delete`, error);
      throw new Error('Error deleting property');
    }
  }
);

export const propertyGetAgentsThunk = createAsyncThunk(
  `${NAME}/agents/getAll`,
  async (id: string) => {
    try {
      return await api.getAgents(id);
    } catch (error) {
      console.error(`${NAME}/agents/getAll`, error);
      throw new Error('Error fetching property agents');
    }
  }
);

export const propertyCreateAgentsThunk = createAsyncThunk(
  `${NAME}/agents/create`,
  async ({ id, payload }: { id: string; payload: IPropertyAgentsRequest }) => {
    try {
      return await api.createPropertyAgents(id, payload);
    } catch (error) {
      console.error(`${NAME}/agents/create`, error);
      throw new Error('Error creating property agents');
    }
  }
);

export const propertyDeleteAgentsThunks = createAsyncThunk(
  `${NAME}/agents/delete`,
  async (payload: idRequest) => {
    try {
      return await api.deletePropertyAgents(payload);
    } catch (error) {
      console.error(`${NAME}/agents/delete`, error);
      throw new Error('Error deleting agents');
    }
  }
);

export const propertyDeleteUserRelationThunk = createAsyncThunk(
  `${NAME}/user/delete`,
  async (payload: deleteUserRequest) => {
    try {
      await api.deleteUserRelationship(payload);

      return payload.userId;
    } catch (error) {
      console.error(`${NAME}/user/delete`, error);
      throw new Error('Error deleting user relationship');
    }
  }
);

export const propertyDeleteInvitationRelationThunk = createAsyncThunk(
  `${NAME}/invitation/delete`,
  async (payload: deleteInvitationRequest) => {
    try {
      await api.deleteInvitationRelationship(payload);

      return payload.invitationId;
    } catch (error) {
      console.error(`${NAME}/invitation/delete`, error);
      throw new Error('Error deleting invitation relationship');
    }
  }
);
