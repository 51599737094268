import { fdeEntryApi } from 'src/api';
import { applicantsApi } from 'src/lib/internal/epa';
import { ApplicantReportPreset } from 'src/ts/enums/applicant';

const api = {
  getReportByEntryId: async (entryId: string) => {
    return await fdeEntryApi.getReportData(entryId);
  },
  getReports(entryId: string) {
    return fdeEntryApi.getReports(entryId);
  },
  getApplicantReportPDF: async (
    applicantId: string,
    preset: ApplicantReportPreset,
    fileName: string
  ) => {
    const result = await applicantsApi.applicantsApplicantIdReportGet(
      applicantId,
      undefined,
      undefined,
      preset,
      {
        responseType: 'blob'
      }
    );
    return { data: result, fileName };
  }
};

export default api;
