// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import { Loader } from 'src/components/atoms/Loader';
import Section from 'src/components/atoms/Section/Section';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';
import ApplicantDetail from './components/ApplicantDetail';
import DocumentResults from './components/DocumentResults';
import HeaderDates from './components/HeaderDates';
import HeaderPrints from './components/HeaderPrints';
import ProofsResults from './components/ProofsResults';

// Redux
import { updateEntryThunk } from 'src/features/entries/services';
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';
import { selectors as propertyFeatureSelectors } from 'src/features/propertyFeature/propertyFeatureSlice';
import { useAppDispatch } from 'src/store';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useFDEReport from 'src/features/fde-report/hooks/useFDEReport';

// Enums
import { EntryStatusEnum } from 'src/ts/enums';

// Types
import { EntryResultEnum, Property } from '@snapptinc/fraud-platform';
import { getFeatureFlagIsLoading } from 'src/features/featureFlag';
import usePropertyFeature from 'src/features/propertyFeature/hooks/usePropertyFeature';
import { configVars } from 'src/helpers';
import { Feature } from 'src/ts/enums/feature';
import { IEntry } from 'src/ts/interfaces';
import IncomeVerificationProofResults from './components/IncomeVerificationProofResults';
import IncomeVerificationResults from './components/IncomeVerificationResults';

const ReportPage: React.FC = () => {
  const { entryId } = useParams();
  const { onGetEntry, onResetEntryReport } = useFDEReport();
  const { isLeasingTeam, isOnSiteRepresentative } = useRole();

  const entryReportData = useSelector(entryReportSelectors.getEntryReport.data);
  const entryReportIsIdle = useSelector(entryReportSelectors.getEntryReport.isIdle);
  const entryReportIsLoading = useSelector(entryReportSelectors.getEntryReport.isLoading);
  const entryReportError = useSelector(entryReportSelectors.getEntryReport.error);

  const entry = entryReportData?.entry as IEntry;

  const property = entryReportData?.property as Property;

  const {
    onGetAll: onGetPropertyFeatures,
    onResetGetAll,
    propertyFeatureIsLoading
  } = usePropertyFeature();
  const propertyFeatures = useSelector(propertyFeatureSelectors.getAll.data);

  const propertyFeatureIsIdle = useSelector(propertyFeatureSelectors.getAll.isIdle);

  const incomeVerificationEnabledProperty = propertyFeatures?.some(
    (propertyFeature) =>
      propertyFeature.code === Feature.INCOME_VERIFICATION && propertyFeature.state === 'enabled'
  );

  const featureFlagIsLoading = useSelector(getFeatureFlagIsLoading);

  const hideIncomeVerificationResults =
    entry?.result === EntryResultEnum.Edited && !incomeVerificationEnabledProperty;

  useEffect(() => {
    if (propertyFeatureIsIdle && property?.id) onGetPropertyFeatures(property?.id);
  }, [propertyFeatureIsIdle, property, onGetPropertyFeatures]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (entryReportIsIdle && entryId) {
      onGetEntry(entryId);
    }
  }, [entryId, entryReportIsIdle, onGetEntry]);

  useEffect(() => {
    if ((isLeasingTeam || isOnSiteRepresentative) && entry?.status === EntryStatusEnum.Ready) {
      dispatch(updateEntryThunk({ ...entry, status: EntryStatusEnum.Viewed }));
    }
  }, [dispatch, entry, isLeasingTeam, isOnSiteRepresentative]);

  useEffect(() => {
    return () => {
      onResetEntryReport();
      onResetGetAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (entryReportError) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.error}
          title="Something was wrong with the report"
          message="We sorry, please try again or contact to support"
        />
      </Section>
    );
  }

  if (entryReportIsLoading || propertyFeatureIsLoading || featureFlagIsLoading) {
    return <Loader isFixed />;
  }

  if (
    // If users are redirected directly to the report page, show the EPA report if the income verification feature is enabled for the property
    incomeVerificationEnabledProperty &&
    entryReportData?.applicant?.id
  ) {
    window.location.replace(
      `${configVars.epa_url}/applicants/${entryReportData.applicant.id}/report?format=html&preset=summary`
    );

    return null;
  }

  return (
    <>
      <HeaderPrints />
      <HeaderDates />
      <ApplicantDetail />
      <DocumentResults />
      <ProofsResults />
      {!hideIncomeVerificationResults && <IncomeVerificationResults />}
      {!hideIncomeVerificationResults && <IncomeVerificationProofResults />}
    </>
  );
};

export default ReportPage;
