// Vendor
import { FC } from 'react';

// Components
import Button, { ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import { Row } from 'src/components/atoms/Row';
import Tag, { TagColor } from 'src/components/atoms/Tag';
import Text from 'src/components/atoms/Text';
import { StyledButton, StyledRow } from './styles';

// Helpers
import { getResultStatusColorTag, transformResultToReadable } from 'src/features/fdeInbox/helpers';

// Enums
import { FeatureFlagKey } from 'src/ts/enums';
import { Feature } from 'src/ts/enums/feature';

// Types
import { EntryStatusBarProps } from './types';
// Hooks
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import usePropertyFeature from 'src/features/propertyFeature/hooks/usePropertyFeature';
import { currencyFormat } from 'src/helpers/currencyFormat';
import { useFeatureFlag } from 'src/hooks';

const EntryStatusBar: FC<EntryStatusBarProps> = ({
  result,
  income,
  disableButton,
  onRemoveFromFolder,
  isDisputesButtonVisible,
  onIncomeCalculationDispute,
  isDisputesButtonDisabled
}) => {
  const { isAccountRepresentative, isAdminOrFdeManager, isSeniorFraudAnalyst } = useUserRoles();
  const { getIsPropertyFeatureActive } = usePropertyFeature();

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );
  const isIncomeVerificationEnabled = getIsPropertyFeatureActive(Feature.INCOME_VERIFICATION);

  const showGrossIncome = income && isIncomeVerificationEnabled;
  const showDisputeButton =
    isIncomeVerificationEnabled &&
    isDisputesButtonVisible &&
    (isAdminOrFdeManager || isSeniorFraudAnalyst);

  return (
    <StyledRow data-testid="EntryStatusBar" justify="space-between" alignItems="center" gap={1}>
      <Row.Col>
        <Row alignItems="center" gap={0.625}>
          <Row.Col>
            <Text>Results</Text>
          </Row.Col>
          <Row.Col>
            <Tag
              label={transformResultToReadable(result)}
              color={getResultStatusColorTag(result, cleanProceedWithCautionFlagEnabled)}
              showBackground
            />
          </Row.Col>
        </Row>
      </Row.Col>

      <Row.Col>
        <Row alignItems="center" gap={0.625}>
          {showGrossIncome && (
            <>
              <Row.Col>
                <Text>Monthly Gross Income</Text>
              </Row.Col>
              <Row.Col>
                <Row alignItems="center">
                  <Tag
                    label={
                      income.value ? currencyFormat(income.value) : income.state || 'Not Available'
                    }
                    color={income.value ? TagColor.green : TagColor.yellow}
                    showBackground
                  />
                </Row>
              </Row.Col>
            </>
          )}
          {showDisputeButton && (
            <Row.Col>
              <StyledButton
                name="dispute-entry-income-verification"
                variant={ButtonVariant.contained}
                size={ButtonSize.medium}
                onClick={onIncomeCalculationDispute}
                isDisabled={isDisputesButtonDisabled}
              >
                Dispute
              </StyledButton>
            </Row.Col>
          )}
        </Row>
      </Row.Col>

      <Row.Col>
        <Row alignItems="center" gap={0.625}>
          <Row.Col alignSelf="flex-end">
            {!isAccountRepresentative && (
              <Button
                name="button-split-entry"
                variant={ButtonVariant.outline}
                size={ButtonSize.medium}
                onClick={onRemoveFromFolder}
                isDisabled={disableButton}
              >
                Remove from Folder
              </Button>
            )}
          </Row.Col>
        </Row>
      </Row.Col>
    </StyledRow>
  );
};

export default EntryStatusBar;
