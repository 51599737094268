import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicantReportPreset } from 'src/ts/enums/applicant';
import api from './api';
import { NAME } from './constants';

export const getEntryReportById = createAsyncThunk(
  `${NAME}/getReportByEntryId`,
  async (entryId: string) => {
    try {
      return await api.getReportByEntryId(entryId);
    } catch (error) {
      console.error(`${NAME}/getReportByEntryId`, error);
      throw new Error('Error fetching the Report through Entry by id');
    }
  }
);

export const getReportsByEntryId = createAsyncThunk(
  `${NAME}/getReportsByEntryId`,
  async (entryId: string) => {
    try {
      return api.getReports(entryId);
    } catch (error) {
      console.error(`${NAME}/getReportsByEntryId`, error);
      throw new Error('Failed to request the reports for that entry');
    }
  }
);

export const getApplicantReportPDF = createAsyncThunk(
  `${NAME}/getApplicantReportPDFThunk`,
  async ({
    applicantId,
    preset,
    fileName
  }: {
    applicantId: string;
    preset: ApplicantReportPreset;
    fileName: string;
  }) => {
    try {
      return await api.getApplicantReportPDF(applicantId, preset, fileName);
    } catch (error) {
      console.error(`${NAME}/getApplicantReportPDFThunk`, error);
      throw new Error('Error getting the Applicant PDF Report');
    }
  }
);
