import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { FeatureFlagKey } from 'src/ts/enums/featureFlag';
import { useFeatureFlag } from '../useFeatureFlag';

export const useHeap = () => {
  const heapIdentityFlagEnabled = useFeatureFlag(FeatureFlagKey.HEAP_IDENTITY);

  const userData = useSelector(authSelectors.user);

  useEffect(() => {
    if (heapIdentityFlagEnabled && userData?.id && window.heap) {
      window.heap.identify(userData.id);
      window.heap.addUserProperties({
        role: userData.role,
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name
      });
      // Clear event properties, so event properties from the our other apps using Heap don't get attached to the events here
      window.heap.clearEventProperties();
    }
  }, [userData, heapIdentityFlagEnabled]);
};
