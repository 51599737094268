import {
  EntryResultEnum,
  ProofSuggestedRulingEnum,
  ProofTypeEnum
} from '@snapptinc/fraud-platform';

export { EntryResultEnum as ProofResultEnum, ProofSuggestedRulingEnum, ProofTypeEnum };

export enum ProofResultCleanProceedWithCautionReasonEnum {
  NONE = 'NONE',
  NEW_DOCUMENT = 'NEW_DOCUMENT',
  POSSIBLE_MALICIOUS = 'POSSIBLE_MALICIOUS'
}

export enum ProofResultEditedReasonEnum {
  NONE = 'NONE',
  TEXT_INSERTION = 'TEXT_INSERTION',
  FRAUDULENT_PDF_PRODUCER = 'FRAUDULENT_PDF_PRODUCER',
  FONT_FAIL = 'FONT_FAIL',
  CREATION_DATE = 'CREATION_DATE',
  VISUAL_EDIT = 'VISUAL_EDIT',
  EASY_PAYROLL = 'EASY_PAYROLL',
  X_REF = 'X_REF',
  TEMPLATE = 'TEMPLATE',
  BLACKLISTED_DOCUMENT = 'BLACKLISTED_DOCUMENT',
  BLACKLISTED_COMPANY = 'BLACKLISTED_COMPANY',
  BLACKLISTED_VISUAL_FEATURE = 'BLACKLISTED_VISUAL_FEATURE',
  LINEARIZATION = 'LINEARIZATION',
  ERROR_NOTIFICATION = 'ERROR_NOTIFICATION',
  WARNING_NOTIFICATION = 'WARNING_NOTIFICATION',
  XMP = 'XMP',
  BYTE_CODE = 'BYTE_CODE',
  OBJECT_NUMBERS = 'OBJECT_NUMBERS',
  OTHER = 'OTHER'
}

export enum ProofResultInsufficientReasonEnum {
  NONE = 'NONE',
  SCANNED_DOCUMENTS = 'SCANNED_DOCUMENTS',
  PRINT_TO_PDF = 'PRINT_TO_PDF',
  INVALID_DOCUMENT_TYPE = 'INVALID_DOCUMENT_TYPE',
  MERGED_DOCUMENT = 'MERGED_DOCUMENT',
  FOREIGN_ISSUED_DOCUMENT = 'FOREIGN_ISSUED_DOCUMENT',
  CROPPED_DOCUMENT = 'CROPPED_DOCUMENT',
  CUSTOM = 'CUSTOM'
}
