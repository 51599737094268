import { LandingLogin } from 'src/components/templates';
import { actions } from 'src/features/auth/authSlice';
import { useAppDispatch } from 'src/store';

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <LandingLogin
      image="/assets/images/snappt-suite-login.png"
      title="Detecting Fraud. Decreasing Evictions."
      text="Data-driven fraud detection software spots fraudulent financial documents other tenant screening tools ignore."
      onClick={() => dispatch(actions.login())}
    />
  );
};

export default LoginPage;
