/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Button, { ButtonDisabledStyle, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';

// Redux
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useFDEReport from 'src/features/fde-report/hooks/useFDEReport';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

import { downloadDocumentPDF } from 'src/helpers';

// Enums
import { ApplicantReportPreset, EntryStatusEnum } from 'src/ts/enums';

// Types
import { IApplicant, IEntry } from 'src/ts/interfaces';

const HeaderPrints: React.FC = () => {
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const { isOnSiteRepresentative, isAdminOrAccountRepresentative } = useRole();
  const {
    onDownloadFcraReport,
    onDownloadPaginatedReport,
    onDownloadReport,
    onResetGetDownloadApplicantReport
  } = useFDEReport();

  const entryReportData = useSelector(entryReportSelectors.getEntryReport.data);

  const applicantReportIsLoading = useSelector(
    entryReportSelectors.getDownloadApplicantReport.isLoading
  );
  const applicantReportError = useSelector(entryReportSelectors.getDownloadApplicantReport.error);
  const applicantReportFile = useSelector(entryReportSelectors.getDownloadApplicantReport.file);
  const applicantReportFileName = useSelector(
    entryReportSelectors.getDownloadApplicantReport.fileName
  );
  const applicantReportPreset = useSelector(entryReportSelectors.getDownloadApplicantReport.preset);

  const { company, entry, applicant } = entryReportData || {};

  useEffect(() => {
    if (!applicantReportIsLoading && applicantReportError === null && applicantReportFile) {
      downloadDocumentPDF(applicantReportFile, applicantReportFileName);
      onResetGetDownloadApplicantReport();
    }
  }, [
    applicantReportIsLoading,
    applicantReportError,
    applicantReportFile,
    onResetGetDownloadApplicantReport
  ]);

  useEffect(() => {
    if (applicantReportError !== null) {
      showSnackbar(VariantType.error, applicantReportError);
      onResetGetDownloadApplicantReport();
    }
  }, [
    applicantReportError,
    onResetGetDownloadApplicantReport,
    showSnackbar,
    VariantType,
    SnackTypes
  ]);

  const fcraLoading =
    applicantReportIsLoading && applicantReportPreset === ApplicantReportPreset.Fcra;

  const paginatedLoading =
    applicantReportIsLoading && applicantReportPreset === ApplicantReportPreset.SummaryAndDocuments;

  const reportLoading =
    applicantReportIsLoading && applicantReportPreset === ApplicantReportPreset.Summary;

  return (
    <Section padding="2rem 3rem 2rem 3rem">
      <Row justify="space-between" alignItems="center">
        <Row.Col>
          {company?.logo ? (
            <img src={company?.logo} alt={company?.name} style={{ maxWidth: '250px' }} />
          ) : (
            <></>
          )}
        </Row.Col>
        <Row.Col className="hiddenPrint">
          <Row justify="space-between">
            {isAdminOrAccountRepresentative && (
              <Button
                name={'download_report_fcra_button'}
                variant={ButtonVariant.ghost}
                isDisabled={fcraLoading}
                isLoading={fcraLoading}
                disabledStyle={ButtonDisabledStyle.white}
                onClick={() => onDownloadFcraReport(entry as IEntry, applicant as IApplicant)}
              >
                <Icon icon="download" />
                &nbsp;Download FCRA report
              </Button>
            )}
            {entry?.status !== EntryStatusEnum.Pending && !isOnSiteRepresentative && (
              <Button
                name={'download_report_paginated_button'}
                variant={ButtonVariant.ghost}
                isDisabled={paginatedLoading}
                isLoading={paginatedLoading}
                disabledStyle={ButtonDisabledStyle.white}
                onClick={() => onDownloadPaginatedReport(entry as IEntry, applicant as IApplicant)}
              >
                <Icon icon="download-paginated" />
                &nbsp;Download paginated report
              </Button>
            )}
            <Button
              name={'download_report_button'}
              variant={ButtonVariant.ghost}
              isDisabled={reportLoading}
              isLoading={reportLoading}
              disabledStyle={ButtonDisabledStyle.white}
              onClick={() => onDownloadReport(entry as IEntry, applicant as IApplicant)}
            >
              <Icon icon="download" />
              &nbsp;Download report
            </Button>
            <Button
              name={'print_report_button'}
              variant={ButtonVariant.ghost}
              onClick={() => window.print()}
            >
              <Icon icon="print" />
              &nbsp;Print report
            </Button>
          </Row>
        </Row.Col>
      </Row>
    </Section>
  );
};

export default HeaderPrints;
