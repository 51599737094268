export enum FeatureFlagKey {
  CLEAN_PROCEED_WITH_CAUTION = 'clean-proceed-with-caution',
  EXTENDED_FA_DOCUMENT_TYPES = 'extended-fa-document-types',
  MONTHLY_GROSS_INCOME_FDE_INBOX_COLUMN = 'monthly-gross-income-fde-inbox-column',
  DOCUMENT_TYPE_IV_FLOW_DROPDOWN = 'document-type-dropdown-iv-flow',
  PAYSTUB_AND_EARNINGS_RENAME = 'paystub-and-earnings-rename',
  IDV_ONLY_WEB_PORTAL = 'peog-466-idv-only-web-portal',
  HEAP_IDENTITY = 'peog-230-heap-enable-user-identity',
  EDITED_RESULT_REASONS_EXTENSION = 'edited-result-reasons-extension'
}

export enum FeatureFlagProjectName {
  FRAUD_PLATFORM = 'fraud-platform'
}
