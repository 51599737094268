import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { configVars } from 'src/helpers';
import userflow from 'userflow.js';

const USERFLOW_TOKEN = configVars.userflow.token;

export const useUserflow = () => {
  const userData = useSelector(authSelectors.user);
  const identityVerificationEnabled = useSelector(authSelectors.identityVerificationEnabled);

  useEffect(() => {
    if (userData?.id) {
      userflow.init(USERFLOW_TOKEN);
      userflow.identify(userData.id, {
        role: userData.role,
        identityVerificationEnabled,
        propertyFeaturesEnabled: userData.property_features_enabled
      });
    }
  }, [identityVerificationEnabled, userData]);

  return userflow;
};
