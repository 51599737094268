import { TypeCard } from 'src/components/molecules/CardPreview';
import { DOCUMENT_TYPES } from 'src/features/DUP/constants';
import { actions } from 'src/features/fde-report/fdeReportSlice';
import { cleanFileName } from 'src/helpers';
import { useAppDispatch } from 'src/store';
import { ProofResultEnum } from 'src/ts/enums';
import { ApplicantReportPreset } from 'src/ts/enums/applicant';
import { IEntry, ProofResultType, ProofType } from 'src/ts/interfaces';
import { IApplicant } from 'src/ts/interfaces/applicant';
import { PROOF_INFO } from '../constants';
import { getApplicantReportPDF, getEntryReportById, getReportsByEntryId } from '../services';

const useFDEReport = () => {
  const dispatch = useAppDispatch();

  const onGetReportsByEntryId = (id: string) => {
    dispatch(getReportsByEntryId(id));
  };

  const onResetGetReportsByEntryId = () => {
    dispatch(actions.resetGetReportsByEntryId());
  };

  const onGetEntry = (id: string) => {
    dispatch(getEntryReportById(id));
  };

  const getProofNote = (status: ProofResultType, proofNote?: string, entryNote?: string) => {
    if (status === ProofResultEnum.Pending) {
      return proofNote || entryNote;
    }
    return proofNote || findProofInfo(status)?.note;
  };

  const getProofTypeStatus = (status: ProofResultType) => {
    return findProofInfo(status)?.type as TypeCard;
  };

  const findProofInfo = (status: ProofResultType) => {
    return PROOF_INFO.find((key) => key.status === status);
  };

  const findDocumentType = (type: ProofType) => {
    return DOCUMENT_TYPES.find((key) => key.value === type)?.label;
  };

  const onResetEntryReport = () => {
    dispatch(actions.resetEntryReport());
  };

  const onDownloadFcraReport = async (
    entry: Pick<IEntry, 'id' | 'inserted_at'>,
    applicant: Pick<IApplicant, 'id' | 'full_name'>
  ) => {
    if (!entry.id) throw new Error('Is missing the Entry identifier to get the FCRA Report');
    if (!applicant.id)
      throw new Error('Is missing the Applicant identifier to get the FCRA Report');
    if (!applicant.full_name)
      throw new Error('Is missing the Applicant name to get the FCRA Report');

    dispatch(
      getApplicantReportPDF({
        applicantId: applicant.id,
        preset: ApplicantReportPreset.Fcra,
        fileName: `FCRA_Report_${cleanFileName(
          applicant.full_name
        )}_${entry.inserted_at?.toLocaleString()}.pdf`
      })
    );
  };

  const onResetGetDownloadApplicantReport = () => {
    dispatch(actions.resetGetApplicantReportPDF());
  };

  const onDownloadPaginatedReport = async (
    entry: Pick<IEntry, 'id'>,
    applicant: Pick<IApplicant, 'id' | 'full_name'>
  ) => {
    if (!entry.id) throw new Error('Is missing the Entry identifier to get the Paginated Report');
    if (!applicant.id)
      throw new Error('Is missing the Applicant identifier to get the Paginated Report');
    if (!applicant.full_name)
      throw new Error('Is missing the Applicant name to get the Paginated Report');

    dispatch(
      getApplicantReportPDF({
        applicantId: applicant.id,
        preset: ApplicantReportPreset.SummaryAndDocuments,
        fileName: `Report_${cleanFileName(applicant.full_name)}.pdf`
      })
    );
  };

  const onDownloadReport = async (
    entry: Pick<IEntry, 'id' | 'inserted_at'>,
    applicant: Pick<IApplicant, 'id' | 'full_name'>
  ) => {
    if (!entry.id) throw new Error('Is missing the Entry identifier to get the Report');
    if (!applicant.id) throw new Error('Is missing the Applicant identifier to get the Report');
    if (!applicant.full_name) throw new Error('Is missing the Applicant name to get the Report');

    dispatch(
      getApplicantReportPDF({
        applicantId: applicant.id,
        preset: ApplicantReportPreset.Summary,
        fileName: `${cleanFileName(applicant.full_name)}_${entry.inserted_at?.toLocaleString()}.pdf`
      })
    );
  };

  return {
    onGetEntry,
    getProofNote,
    getProofTypeStatus,
    onResetEntryReport,
    onDownloadFcraReport,
    onDownloadPaginatedReport,
    onDownloadReport,
    onResetGetDownloadApplicantReport,
    findProofInfo,
    findDocumentType,
    onGetReportsByEntryId,
    onResetGetReportsByEntryId
  };
};

export default useFDEReport;
